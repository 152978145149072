@font-face {
  font-family: Bookman;
  src: url("./BookmanRegular.otf") format("opentype");
}

@font-face {
  font-family: ScalaSans;
  src: url("./ScalaSans-Regular.otf") format("opentype");
}

@font-face {
  font-family: Libertine;
  src: url("./LibertineCapitals.otf") format("opentype");
}

@font-face {
  font-family: Bookman;
  font-weight: bold;
  src: url("./BookmanBold.otf") format("opentype");
}

@font-face {
  font-family: Bookman;
  font-style: italic;
  src: url("./BookmanItalic.otf") format("opentype");
}

@font-face {
  font-family: Bookman;
  font-weight: bold;
  font-style: italic;
  src: url("./BookmanBoldItalic.otf") format("opentype");
}

@font-face {
  font-family: Solbera;
  src: url("./Solbera\ Imitation.otf") format("opentype");
}

@font-face {
  font-family: ScalaSans;
  font-weight: bold;
  src: url("./ScalaSans-Bold.otf") format("opentype");
}

@font-face {
  font-family: ScalaSans;
  font-style: italic;
  src: url("./ScalaSans-Italic.otf") format("opentype");
}

@font-face {
  font-family: ScalaSans;
  font-weight: bold;
  font-style: italic;
  src: url("./ScalaSans-BoldItalic.otf") format("opentype");
}

@font-face {
  font-family: ScalaSansAllCaps;
  src: url("./scalasans-caps.otf") format("opentype");
}

@font-face {
  font-family: ScalaSansAllCaps;
  font-weight: bold;
  src: url("./ScalaSansCaps-Bold.otf") format("opentype");
}

@font-face {
  font-family: Zatanna;
  font-weight: normal;
  font-style: normal;
  src: url("./Zatanna\ Misdirection\ Bold.otf") format("opentype");
}

@font-face {
  font-family: "Mrs Eaves";
  src: local("Mrs Eaves Small Caps Small Caps"),
    local("Mrs-Eaves-Small-Caps-Small-Caps"),
    url("./MrsEavesSmallCapsSmallCaps.woff2") format("woff2"),
    url("./MrsEavesSmallCapsSmallCaps.woff") format("woff"),
    url("./MrsEavesSmallCapsSmallCaps.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: rgb(230, 231, 234);
}

.app-container {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}

.left-controls {
  position: relative;
  width: 60px;
  height: 1132px;
  background-color: rgba(240, 242, 245);
  outline: 1px solid rgb(115, 120, 120);
  box-shadow: 0px 0px 4px rgb(33, 33, 33);
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: -40px;
  margin-left: 40px;
  transition: all 0.2s;
  z-index: 1;
}
.left-controls * {
  opacity: 0;
}
.left-controls:hover * {
  opacity: 1;
}

.left-controls:hover {
  margin-right: 0px;
  margin-left: 0px;
  opacity: 1;
}
.left-controls .controls-label {
  opacity: 1;
  position: absolute;
  top: 55px;
  left: -60px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: rgb(66, 66, 66);
  rotate: 270deg;
  transition: all 0.2s;
  width: 140px;
}
.left-controls:hover .controls-label {
  opacity: 0;
}
.left-control-button-holder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s;
}

.left-control-button {
  margin-top: 16px;
  width: 32px;
  height: 32px;
  text-align: center;
  background-color: transparent;
  color: rgb(55, 53, 47);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  border: 2px solid rgb(55, 53, 47);
  box-shadow: 0px 0px 2px rgb(55, 53, 47);
  border-radius: 4px;
}

.left-control-button-holder:hover {
  background-color: rgba(255, 0, 0, 0.1);
}

.left-control-button-holder:hover .left-control-button {
  color: #ea3032;
  box-shadow: 0px 0px 10px #ea3032;
  border: 2px solid #ea3032;
}

.left-control-button-label {
  font-size: 8px;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: rgb(55, 53, 47);
  font-weight: 900;
  text-align: center;
  width: 100%;
  margin-top: 8px;
}

.left-control-button-holder:hover .left-control-button-label {
  color: #ea3032;
}

.right-controls {
  position: relative;
  width: 220px;
  height: 1132px;
  background-color: rgba(240, 242, 245);
  outline: 1px solid rgb(115, 120, 120);
  box-shadow: 0px 0px 4px rgb(33, 33, 33);
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 200px;
  margin-left: -200px;
  transition: all 0.2s;
  z-index: 1;
  overflow: hidden;
}
.right-controls * {
  opacity: 0;
}
.right-controls-toggled * {
  opacity: 1;
}

.right-controls:hover * {
  opacity: 1;
}

.right-controls:hover {
  margin-right: 0px;
  margin-left: 0px;
  opacity: 1;
}

.right-controls-toggled {
  margin-right: 0px;
  margin-left: 0px;
  opacity: 1;
}

.right-controls .controls-label {
  opacity: 1;
  position: absolute;
  top: 46px;
  right: -30px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: rgb(66, 66, 66);
  rotate: 90deg;
  transition: all 0.2s;
}
.right-controls:hover .controls-label {
  opacity: 0;
}

.right-controls-toggled .controls-label {
  opacity: 0;
}

.page-container {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 38px;
  padding-top: 32px;
  padding-bottom: 20px;
  bottom: 0px;
  overflow: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.file-list {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 38px;
  padding-top: 32px;
  padding-bottom: 20px;
  bottom: 0px;
  overflow: scroll;
  display: flex;
  font-family: ScalaSans;
  justify-content: center;
  flex-wrap: wrap;
}

.file-item {
  width: 280px;
  height: 280px;
  background-color: rgba(240, 242, 245);
  box-shadow: 0px 0px 4px rgb(33, 33, 33);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  margin: 10px;
}

.file-item-title {
  width: 100%;
  padding: 8px;
  border-top: 1px solid rgb(115, 120, 120);
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.file-item:hover .file-item-title {
  color: maroon;
}

.new-file-item-title {
  width: 100%;
  padding: 8px;
  border-top: 1px solid rgb(115, 120, 120);
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.page {
  position: relative;
  outline: 1px solid rgb(115, 120, 120);
  box-shadow: 0px 0px 4px rgb(33, 33, 33);
  width: 800px;
  height: 1132px;
  background-size: cover;
  overflow: visible;
  z-index: 2;
  transition: all 0.2s;
}

.page-default {
  background-image: url("/public/page.jpg");
}
.page-alternate {
  background-image: url("/public/page_alternate.jpg");
}
.page-beige {
  background-image: url("/public/page_beige.jpg");
}
.page-blue-alternate {
  background-image: url("/public/page_blue-alternate.jpg");
}
.page-crimson-alternate {
  background-image: url("/public/page_crimson-alternate.jpg");
}
.page-black {
  background-image: url("/public/page_black.jpg");
}

.image-controller {
  position: absolute;
  z-index: 4;
}
.image-controller:hover {
  z-index: 11;
}

.image-button {
  display: none;
}

.image-controller .image-controls {
  position: absolute;
  left: -26px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
}

.image-controls .image-button {
  background-color: white;
  align-items: center;
  justify-content: center;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  border: 1px solid rgb(33, 33, 33);
  margin-bottom: -1px;
  overflow: hidden;
  height: 24px;
  width: 24px;
}

.image-controls .image-button:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.image-controls .image-button:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.image-controller:hover .image-button {
  opacity: 1;
  display: flex !important;
}

.image-button:hover {
  cursor: pointer;
  background-color: black;
  color: white;
}

.image-button-selected {
  background-color: orange !important;
  color: black !important;
}

.image-controller-cut {
  z-index: 10;
}

.image-controller-move {
  z-index: 10;
}

.image-controller-move:hover {
  cursor: move;
}

.image-controller-cut:hover {
  cursor: crosshair;
}
.image-properties {
  width: 100%;
  position: absolute;
  top: 0px;
}

.image-controller .image-properties {
  position: absolute;
  right: 12px;
  bottom: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(230, 231, 234);
  border: 1px solid rgb(33, 33, 33);
  border-radius: 4px;
  overflow: hidden;
  z-index: 2;
}
.image-controller:hover .image-properties {
  z-index: 10;
}

.image-properties .section-title {
  font-size: 11px;
  font-weight: 500;
  margin: 3px;
}

.section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px;
  width: calc(100% - 20px);
}
.section:nth-child(odd) {
  background-color: rgb(200, 201, 204);
}

.double-input-holder {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.small-number-input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0px;
  width: 24px;
  margin: 2px;
  font-size: 11px;
  text-align: center;
}

.folio-empty-image {
  background-color: rgba(0, 0, 0, 0.2);
}

.clip-path-points {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}

.clip-path-point {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: crimson;
  pointer-events: none;
  outline: 2px solid black;
}
.clip-path-point-in-progress:first-child {
  background-color: yellow;
  pointer-events: all;
}

.clip-path-point-in-progress {
  background-color: green;
}
.clip-path-point-hover {
  background-color: blue;
}
.clip-path-point-on-border {
  background-color: white;
}

.send-to-back {
  z-index: 0;
}

.column {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 3;
}

.table {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 3;
  font-family: ScalaSans;
}
.table .table-header {
  font-family: ScalaSansAllCaps;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 4px;
}
input.table-header {
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  outline: none;
  width: 100%;
  caret-color: rgb(55, 53, 47);
  color: rgb(55, 53, 47);
}

.table table {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  font-size: 14px;
  line-height: 20px;
  border-spacing: 0px;
  width: 100%;
  table-layout: auto;
  caret-color: rgb(55, 53, 47);
  color: rgb(55, 53, 47);
}

input.th-input {
  font-family: ScalaSans;
  font-weight: bold;
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  outline: none;
  width: fit-content;
  caret-color: rgb(55, 53, 47);
  color: rgb(55, 53, 47);
}
input.td-input {
  font-family: ScalaSans;
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  outline: none;
  width: fit-content;
  caret-color: rgb(55, 53, 47);
  color: rgb(55, 53, 47);
}

.th-highlighted {
  background-color: rgba(0, 0, 0, 0.1);
}
.td-highlighted {
  background-color: rgba(0, 0, 0, 0.1);
}

.floating-text {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 3;
}

.table table thead {
  display: table-row-group;
  font-weight: bold;
}
.table table tr {
  white-space: nowrap;
}
.table table tr td {
  padding: 0 1.5px;
  margin: 0px;
  white-space: nowrap;
}
.table table thead th {
  vertical-align: bottom;
  padding: 0 1.5px;
  margin: 0px;
  white-space: nowrap;
}

.table table tbody tr:nth-child(odd) {
  background-color: #e0e5c1;
}

.page:hover .react-draggable {
  outline: 2px solid rgba(0, 0, 0, 0.1);
}

.react-draggable:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.top-handle {
  z-index: 2;
  position: absolute;
  left: -2px;
  top: -4px;
  right: -2px;
  height: 8px;
  cursor: ns-resize;
}

.bottom-handle {
  z-index: 2;
  position: absolute;
  left: -2px;
  bottom: -4px;
  right: -2px;
  height: 8px;
  cursor: ns-resize;
}

.right-handle {
  z-index: 20;
  position: absolute;
  right: -4px;
  top: -2px;
  bottom: -2px;
  width: 8px;
  cursor: ew-resize;
}

.left-handle {
  z-index: 20;
  position: absolute;
  left: -4px;
  bottom: -2px;
  top: -2px;
  width: 8px;
  cursor: ew-resize;
}

.image-controller:hover .top-handle {
  background: rgba(0, 0, 0, 0.5);
}

.image-controller:hover .bottom-handle {
  background: rgba(0, 0, 0, 0.5);
}

.image-controller:hover .right-handle {
  background: rgba(0, 0, 0, 0.5);
}

.image-controller:hover .left-handle {
  background: rgba(0, 0, 0, 0.5);
}

.image-controller-cut-frame {
  position: absolute;
  cursor: cell;
  background-color: rgba(255, 0, 0, 0.25);
  z-index: 11;
  pointer-events: none;
}
.image-controller-cut-frame-top {
  left: 0px;
  right: 0px;
  top: 0px;
  height: 4px;
}
.image-controller-cut-frame-left {
  left: 0px;
  bottom: 0px;
  top: 0px;
  width: 4px;
}
.image-controller-cut-frame-right {
  bottom: 0px;
  right: 0px;
  top: 0px;
  width: 4px;
}
.image-controller-cut-frame-bottom {
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 4px;
}

.single-page-container {
  z-index: 101;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 4px;
  margin-bottom: 20px;
}

.image-controller-selected {
  outline: 2px solid orange;
  box-shadow: 0px 0px 10px rgb(33, 33, 33);
}
.selected-column {
  outline: 2px solid orange !important;
  box-shadow: 0px 0px 10px rgb(33, 33, 33) !important;
}
.selected-table {
  outline: 2px solid orange !important;
  box-shadow: 0px 0px 10px rgb(33, 33, 33) !important;
}
.selected-floating-text {
  outline: 2px solid orange !important;
  box-shadow: 0px 0px 10px rgb(33, 33, 33) !important;
}

.sections-title {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 4px 12px;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  background-color: rgb(200, 201, 204);
  margin-top: -1px;
}

.sections-holder {
  margin-bottom: 12px;
}

.layer-color {
  border: 1px solid black;
  outline: 1px solid white;
  margin-right: 9px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.color-picker-holder {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.color-picker-done {
  margin-top: 12px;
  padding: 4px 12px;
  border: 1px solid #c73032;
  box-shadow: 0px 0px 2px #c73032;
  border-radius: 2px;
  font-size: 12px;
  cursor: pointer;
  color: #c73032;
}

.color-picker-done:hover {
  color: #ea3032;
  box-shadow: 0px 0px 10px #ea3032;
  border: 1px solid #ea3032;
}

.component-buttons-holder {
  position: absolute;
  left: 0px;
  top: -26px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.component-buttons-holder-left {
  left: -26px;
  top: 0px;
  right: auto;
}

.component-button {
  background-color: white;
  color: black;
  display: none;
  align-items: center;
  justify-content: center;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  border: 1px solid rgb(33, 33, 33);
  margin-right: -1px;
  overflow: hidden;
  height: 24px;
  width: 24px;
}

.component-buttons-holder .component-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.component-buttons-holder .component-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.component:hover .component-button {
  display: flex;
}

.component-buttons-holder:hover .component-button {
  display: flex;
}

.component-button:hover {
  cursor: pointer;
  background-color: black;
  color: white;
}

.th-button {
  position: relative;
  right: 4px;
  top: 1px;
  color: rgb(66, 66, 66);
  cursor: pointer;
  z-index: 3;
}
.th-button:hover {
  opacity: 1;
  color: #ea3032;
}

.bring-to-front {
  z-index: 12;
}

.add-column-button {
  width: 10px;
  bottom: -2px;
  top: -2px;
  right: -18px;
  background-color: white;
  position: absolute;
  border: 2px solid orange;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: orange;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.add-column-button:hover {
  background-color: rgb(230, 231, 234);
}

.add-row-button {
  height: 10px;
  left: -2px;
  bottom: -18px;
  right: -2px;
  background-color: white;
  position: absolute;
  border: 2px solid orange;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  color: orange;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.add-row-button:hover {
  background-color: rgb(230, 231, 234);
}

[contenteditable] {
  outline: 0px solid transparent;
}

.side-bar {
  z-index: 31;
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(240, 242, 245);
  outline: 1px solid rgb(115, 120, 120);
  box-shadow: 0px 0px 4px rgb(33, 33, 33);
}
.side-bar-internal {
  display: flex;
  align-items: center;
  width: 840px;
  margin-left: -160px;
  justify-content: space-between;
}

.insert-page-panel {
  z-index: 100;
  width: 800px;
  margin-left: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.2s;
  cursor: pointer;
  margin-top: -20px;
  padding: 10px 0px;
  margin-bottom: -4px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  outline: 1px solid rgb(115, 120, 120);
  box-shadow: 0px 0px 4px rgb(33, 33, 33);
  caret-color: rgb(55, 53, 47);
  color: rgb(55, 53, 47);
}
.insert-page-panel:hover {
  opacity: 1;
  background-color: rgb(246, 241, 221);
}
.shimmer-button {
  background-color: rgba(0, 255, 0, 0.1);
}

.shimmer-button .left-control-button {
  color: green !important;
  border: 2px solid green !important;
  box-shadow: 0px 0px 2px green !important;
}

.shimmer-button .left-control-button-label {
  color: green !important;
}

.sidebar-control-button-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  padding: 2px 6px;
  padding-right: 12px;
}

.sidebar-control-button {
  width: 28px;
  height: 32px;
  text-align: center;
  background-color: transparent;
  color: rgb(55, 53, 47);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  border-radius: 4px;
}

.sidebar-control-button-holder:hover {
  background-color: rgba(255, 0, 0, 0.1);
}

.sidebar-control-button-holder:hover .sidebar-control-button {
  color: #ea3032;
}

.sidebar-control-button-label {
  font-size: 8px;
  text-transform: uppercase;
  color: rgb(55, 53, 47);
  font-weight: 900;
  text-align: center;
}

.sidebar-control-button-holder:hover .sidebar-control-button-label {
  color: #ea3032;
}

.vertical-hightlight {
  position: absolute;
  width: 2px;
  top: 0px;
  bottom: 0px;
  background-color: orange;
}
.horizontal-hightlight {
  position: absolute;
  height: 2px;
  left: 0px;
  right: 0px;
  background-color: orange;
}

.public-DraftEditor-content h1 + div {
  text-indent: 0px;
}

.public-DraftEditor-content div + div {
  text-indent: 12px;
}

.page-button {
  position: absolute;
  right: 4px;
  top: 4px;
  opacity: 0;
  color: rgb(66, 66, 66);
  cursor: pointer;
  z-index: 3;
}
.page:hover .page-button {
  opacity: 1;
}

.page:hover .page-button:hover {
  opacity: 1;
  color: #ea3032;
}

.fucinus-container {
  position: fixed;
  width: 310px;
  top: 60px;
  right: 20px;
  bottom: 20px;
  background-color: rgba(240, 242, 245);
  outline: 1px solid rgb(115, 120, 120);
  box-shadow: 0px 0px 4px rgb(33, 33, 33);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fucinus-chat {
  width: 100%;
  height: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
}

.fucinus-textarea {
  border-top: 1px solid rgba(33, 33, 33, 0.4);
  background-color: rgba(33, 33, 33, 0.05);
  width: 100%;
}

.fucinus-chat-item {
  font-family: "Bookman";
  padding: 0px 12px;
  font-size: 13px;
}

.fucinus-chat-item-assistant {
  background-color: rgba(33, 33, 33, 0.1);
  padding: 4px 12px;
}

.fucinus-chat {
  overflow: scroll;
}

.select-input {
  font-size: 12px;
}

.image-controller-removing-background {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-controller-removing-background-indicator {
  color: orange;
}

.empty-header-one::before {
  content: "Heading 1";
  color: #888;
  position: absolute;
}

.empty-header-two::before {
  content: "Heading 2";
  color: #888;
  position: absolute;
}

.empty-header-three::before {
  content: "Heading 3";
  color: #888;
  position: absolute;
}

.section-button-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.component-properties-button {
  cursor: pointer;
  margin: 4px;
  font-size: 12px;
  border: 1px solid black;
  border-radius: 4px;
  padding: 2px 12px;
}

.component-properties-button:hover {
  background-color: orange;
}

.component-properties-button-danger:hover {
  background-color: #c73032;
  border-color: #c73032;
  color: white;
}

.image-generation {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
}

.image-generation-input {
  border: none;
  outline: none;
  background-color: transparent;
  color: #555;
  width: 320px;
  text-align: center;
  max-width: calc(100% - 20px);
  height: auto;
  font-family: "Bookman";
  font-weight: bold;
  color: #333;
}

.image-generation-input::placeholder {
  color: #444;
}

.chat-component {
  font-family: "Bookman";
  position: absolute;
  font-size: 14px;
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 1000;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(6px);
  display: flex;
  justify-content: center;
}

.chat-input {
  border: none;
  outline: none;
  background-color: white;
  height: auto;
  font-family: "Bookman";
  resize: none;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0px 86px;
  padding: 10px 14px;
  vertical-align: middle;
  border-radius: 4px;
  bottom: 56px;
  border: 1px solid black;
}

.chat-component-controls {
  position: absolute;
  left: 0px;
  bottom: 20px;
  padding: 0px 84px;
}

.chat-message {
  margin-bottom: 8px;
}

.chat-message-user {
  font-weight: bold;
  background-color: rgba(168, 144, 125, 0.25);
  padding: 1px 14px;
  border-radius: 2px;
  margin-left: -14px;
  margin-right: -14px;
}

.chat-component-inner {
  position: relative;
  width: 720px;
  padding: 90px 100px;
  margin-left: -160px;
}

.chat-component-items {
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: 96px;
  top: 76px;
  padding: 0px 100px;
  overflow: scroll;
}

#root {
  position: absolute;
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
}

.page-selection-box {
  pointer-events: none;
  position: absolute;
  background-color: rgba(0, 127, 250, 0.25);
}

.column-background-none {
  background-image: none;
}
.column-background-default {
  background-image: url("/public/page.jpg");
}
.column-background-alternate {
  background-image: url("/public/page_alternate.jpg");
}
.column-background-beige {
  background-image: url("/public/page_beige.jpg");
}
.column-background-blue-alternate {
  background-image: url("/public/page_blue-alternate.jpg");
}
.column-background-crimson-alternate {
  background-image: url("/public/page_crimson-alternate.jpg");
}
.column-background-black {
  background-image: url("/public/page_black.jpg");
}

.editor-inherit-padding .DraftEditor-root {
  top: inherit !important;
  left: inherit !important;
  right: inherit !important;
  bottom: inherit !important;
}

.template-button {
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  padding: 2px;
}
.template-button:hover {
  background-color: white;
  border-top: 1px solid crimson;
  border-bottom: 1px solid crimson;
  color: crimson;
}

.side-bar-file-name {
}

.side-bar-file-name-input {
  font-family: Bookman;
  border: none;
  outline: none;
  background-color: transparent;
  color: #555;
  width: 280px;
  text-align: left;
  height: auto;
  color: #333;
  font-size: large;
  padding: 6px;
}

.file-item-actions {
  position: relative;
  color: #888;
  opacity: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-item:hover .file-item-actions {
  opacity: 1;
}

.file-item-actions:hover {
  color: maroon;
}

.file-item-action {
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 20px;
  left: -64px;
  width: 84px;
  padding: 4px;
  background-color: rgba(240, 242, 245, 0.8);
  border: 1px solid rgb(115, 120, 120);
  text-align: l;
}

.file-item-action:nth-child(2) {
  top: 20px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.file-item-action:nth-child(3) {
  top: 44px;
}
.file-item-action:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.file-item-action:hover {
  background-color: rgba(240, 242, 245, 0.8);
  text-decoration: underline;
}

.file-item-actions:hover .file-item-action {
  display: flex;
}
