.column .DraftEditor-root {
  font-family: Bookman;
  cursor: text;
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  overflow: hidden;
}

.column .public-DraftEditor-content {
  white-space: pre-wrap;
  word-break: break-word;
  padding: 3px 2px;
  caret-color: rgb(55, 53, 47);
  color: rgb(55, 53, 47);
  outline: none;
  line-height: 20px;
  font-size: 13px;
  text-indent: 12px;
}
h1 {
  text-indent: 0;
  font-family: Mrs Eaves;
  color: #58180d;
}
h2 {
  text-indent: 0;
  font-family: Mrs Eaves;
  color: #58180d;
  padding-bottom: 4px;
  border-bottom: 2px solid #c0ad6a;
  margin-bottom: 4px;
  line-height: 26px;
}
h3 {
  text-indent: 0;
  font-family: Mrs Eaves;
  color: #58180d;
  margin-bottom: 4px;
}

[contenteditable]:empty:after,
.forcePlaceholder:after {
  content: attr(placeholder);
  -webkit-text-fill-color: rgba(55, 53, 47, 0.5);
}

.floating-text .DraftEditor-root {
  font-family: Zatanna;
  cursor: text;
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
  right: 4px;
  overflow: hidden;
  color: attr(color);
}
.floating-text .public-DraftEditor-content {
  white-space: pre-wrap;
  word-break: break-word;
  padding: 3px 2px;
  outline: none;
  line-height: 20px;
  font-size: 13px;
}

callout {
  display: block;
  background-color: white;
  border-left: 2px solid maroon;
  border-right: 2px solid maroon;
  padding-left: 6px;
  padding-right: 6px;
  position: relative;
}

callout:first-of-type {
  padding-top: 6px;
  margin-top: 8px;
}
callout:last-of-type {
  margin-bottom: 8px;
  padding-bottom: 6px;
}

callout:first-of-type::after {
  content: " ";
  position: absolute;
  left: -4px;
  top: -2px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: maroon;
}
callout:first-of-type::before {
  content: " ";
  position: absolute;
  right: -4px;
  top: -2px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: maroon;
}
callout:last-of-type::after {
  content: " ";
  position: absolute;
  right: -4px;
  bottom: -2px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: maroon;
}
callout:last-of-type::before {
  content: " ";
  position: absolute;
  left: -4px;
  bottom: -2px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: maroon;
}

bordercallout {
  display: block;
  background-color: rgb(224, 228, 196);
  padding-left: 6px;
  padding-right: 6px;
  position: relative;
  border-left: 0.5px solid black;
  border-right: 0.5px solid black;
}

bordercallout:first-of-type {
  border-top: 2px solid black;
  padding-top: 6px;
  margin-top: 16px;
}
bordercallout:last-of-type {
  border-bottom: 2px solid black;
  margin-bottom: 16px;
  padding-bottom: 6px;
}

bordercallout:first-of-type::before {
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 9px 20px;
  border-color: transparent transparent black transparent;
  position: absolute;
  left: 0px;
  top: -11px;
}
bordercallout:first-of-type::after {
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 9px 20px;
  border-color: transparent transparent black transparent;
  position: absolute;
  right: 0px;
  top: -11px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

bordercallout:last-of-type::before {
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 9px 20px;
  border-color: transparent transparent black transparent;
  position: absolute;
  right: 0px;
  bottom: -11px;
  rotate: 180deg;
}
bordercallout:last-of-type::after {
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 9px 20px;
  border-color: transparent transparent black transparent;
  position: absolute;
  left: 0px;
  bottom: -11px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  rotate: 180deg;
}

.container-callout {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  border-left: 2px solid maroon;
  border-right: 2px solid maroon;
}
.container-callout-circle {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: maroon;
}

.container-bordercallout {
  background-color: rgb(224, 228, 196);
  position: absolute;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  box-shadow: 0px 0px 4px black;
}

.container-bordercallout-bottomleft-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 9px 20px;
  border-color: transparent transparent black transparent;
  position: absolute;
  left: 0px;
  bottom: -11px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  rotate: 180deg;
}
.container-bordercallout-topleft-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 9px 20px;
  border-color: transparent transparent black transparent;
  position: absolute;
  left: 0px;
  top: -11px;
}
.container-bordercallout-topright-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 9px 20px;
  border-color: transparent transparent black transparent;
  position: absolute;
  right: 0px;
  top: -11px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.container-bordercallout-bottomright-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 9px 20px;
  border-color: transparent transparent black transparent;
  position: absolute;
  right: 0px;
  bottom: -11px;
  rotate: 180deg;
}
